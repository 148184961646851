
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import { listProfileFeatures, listEnterpriseFeatures } from "@/graphql/queries";
import slugify from "slugify";
import { listPageTables } from "@/graphql/queries";

interface Business {
  id: string;
  name: string;
  slugname: string;
  metadata: any;
}

export default defineComponent({
  name: "business",
  setup() {
    const submitButton = ref<HTMLElement | null>(null);

    var userId = ref("");
    var isPhoneVerified = ref(false);
    var user = ref();

    Auth.currentAuthenticatedUser().then((user) => {
      user.value = user;
      userId.value = user.username;
      isPhoneVerified.value = user.attributes.phone_number_verified;
    });

    setCurrentPageBreadcrumbs("Business", "Suas ideias de negócios");

    return {
      user,
      isPhoneVerified,
      submitButton,
    };
  },
  mounted() {
    this.fullscreenLoading = true;
    this.loadPage();
    setTimeout(() => {
      this.loadProfile();
      this.fullscreenLoading = false;
    }, 3000);
  },
  data() {
    return {
      page: {
        banner: "",
        bannerSmall: "",
      },
      currentActive: 1,
      tabs: [
        {
          id: 1,
          icon: "fa-solid fa-lightbulb",
          label: "Ideias de Negócios",
        },
      ],
      fullscreenLoading: false,
      bgImageSmall: "",
      profile: {
        id: "",
        username: "",
        avatar: "",
        name: "",
        birthday: "",
        age: 0,
        gender: "",
        verified: false,
        geolocation: null,
        metadata: "",
        enabled: true,
        auth_signup_id: null,
      },
      accountVerification: {
        phone_number: "",
        email: "",
      },
      address: {
        cep: "",
        street: "",
        number: "",
        city: "",
        state: "",
        country: "",
      },
      business_collection: [] as Business[],
      pageId: "caldeiracloud_business_pagetable",
    };
  },
  methods: {
    setActive(id) {
      this.currentActive = id;
    },
    async loadPage() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageTables, {
            filter: {
              pageId: {
                eq: this.pageId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageTables.items.length > 0) {
          this.page = response.data.listPageTables.items[0];
          if (this.page) {
            const banner = await Storage.get(this.page.banner, {
              level: "public",
            });
            this.page.banner = banner;

            const bannerSmall = await Storage.get(this.page.bannerSmall, {
              level: "public",
            });
            this.page.bannerSmall = bannerSmall;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadEnterprise(id) {
      const response: any = await API.graphql(
        graphqlOperation(listEnterpriseFeatures, {
          filter: {
            id: {
              eq: id.business_id,
            },
            enabled: {
              eq: true,
            },
          },
        })
      );

      if (response.data.listEnterpriseFeatures.items.length > 0) {
        const business: Business =
          response.data.listEnterpriseFeatures.items[0]; // Add type annotation for 'business'
        if (business) {
          const metadata = JSON.parse(business.metadata);
          business.metadata = metadata;
          const banner = await Storage.get(business.metadata.bannerHeader, {
            level: "public",
          });
          business.metadata.bannerHeader = banner;
          this.business_collection.push(business);
        }
      }
    },
    async loadProfile() {
      try {
        var username = null;
        await Auth.currentAuthenticatedUser().then((user) => {
          username = user.username;
        });

        if (username) {
          const response: any = await API.graphql(
            graphqlOperation(listProfileFeatures, {
              filter: {
                auth_signup_id: {
                  eq: username,
                },
                enabled: {
                  eq: true,
                },
              },
            })
          );

          if (response.data.listProfileFeatures.items.length > 0) {
            this.buildProfile(response.data.listProfileFeatures.items[0]);
          } else {
            Auth.currentAuthenticatedUser().then((user) => {
              this.profile.username = slugify(
                user.attributes.given_name,
                "."
              ).toLowerCase();
              this.profile.name = user.attributes.given_name;
              this.profile.auth_signup_id = user.username;
              this.accountVerification = {
                phone_number: user.attributes.phone_number,
                email: user.attributes.email,
              };
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    buildProfile(profile) {
      this.profile = profile;
      if (this.profile) {
        let metadata = JSON.parse(this.profile.metadata);
        this.profile.metadata = metadata;
        this.address = metadata.address ? metadata.address : this.address;
        this.accountVerification = {
          phone_number: metadata.phone_number,
          email: metadata.email,
        };

        if (metadata.business_collection) {
          metadata.business_collection.forEach((each) => {
            this.loadEnterprise(each);
          });
        }
      }
    },
  },
});
